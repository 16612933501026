





















import { defineComponent } from '@vue/composition-api'
import router from '@/router'

export default defineComponent({
  name: 'MobileProduct',
  setup() {
    const list = [
      {
        image:
          'https://zyb-zhike-file.cdnjtzy.com/mis/a1d89244-d573-49fe-9ed1-7ed0aeb93fff.png',
        title: '高质量作业',
        sub: '作业数字化 重塑教与学',
        desc: '依托海量题库和大数据，推进作业来源、设计、布置、完成、批改、分析、反馈、辅导等全流程数字化，以助力老师精准高效教，学生精准高效学，校局精准高效管。',
        link: '/zhzy'
      },
      {
        image:
          'https://zyb-zhike-file.cdnjtzy.com/mis/7bed62f6-ec5f-46e4-993d-30b323207fd8.png',
        title: '云思课后服务',
        sub: '尊重个性 陪伴成长',
        desc: ' 打造覆盖课后服务全场景的“1+4”整体解决方案，高质量、轻负担打造课后服务系统，满足区域和学校全方位分层次课后服务需求。',
        link: '/khfw'
      },
      {
        image:
          'https://zyb-zhike-file.cdnjtzy.com/mis/7b558866-2222-40a4-8453-cf63e7808feb.png',
        title: '云思智学智慧体育',
        sub: '以体育人 面向未来',
        desc: '云思智学智慧体育依托智能穿戴设备、智能体测终端和AI摄像头，精准采集学生运动心率及体质信息，基于人体关节模型识别、运动姿态识别、AI视觉分析等核心技术能力，结合运动诊断知识系统，分析学生技术动作等关键数据，生成个性化运动处方和班级分析报告，帮助教师管控课堂风险、开展精准教学, 辅助管理者科学决策，提高体育教学质量。',
        link: '/zhty'
      },
      {
        image:
          'https://zyb-zhike-file.cdnjtzy.com/mis/f11e9fa5-f0c6-4ebf-be9a-d3307b09e9bd.png',
        title: '智慧课堂',
        sub: '“精” “智” 每一课',
        desc: ' 全量采集师生过程性数据，结合人工智能、大数据技术应用，打造精准教学解决方案，实现从传统教学向高质量智慧教学升级。',
        link: '/zhkt'
      }
    ]
    const handleRedirect = (path: string) => {
      router.push({ path })
    }
    return {
      list,
      handleRedirect
    }
  }
})
